import React from 'react'

export const HeaderSocials = () => {
  return (
    <div className="home__socials">
        <a href="https://www.instagram.com/kativucraig?igsh=eWUyZnZ1b2hpZHVx" className="home__social-link" target='_blank' rel="noreferrer">
            <i className="fa-brands fa-instagram"></i>
        </a>

        <a href="#" className="home__social-link" target='_blank' rel="noreferrer">
            <i className="fa-brands fa-twitter"></i>
        </a>

        <a href="https://www.facebook.com/craig.mikey?mibextid=ZbWKwL" className="home__social-link" target='_blank' rel="noreferrer">
            <i className="fa-brands fa-facebook"></i>
        </a>

        <a href="https://www.linkedin.com/in/tafadzwa-kativu-a3470a273?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app" className="home__social-link" target='_blank' rel="noreferrer">
            <i className="fa-brands fa-linkedin"></i>
        </a>

        <a href="https://github.com/KativuCraig" className="home__social-link" target='_blank' rel="noreferrer">
            <i className="fa-brands fa-github"></i>
        </a>
    </div>
  )
}

export default HeaderSocials