const Data = [
    {
      id: 1,
      category: "education",
      icon: "icon-graduation",
      year: "2019",
      title: "Academic Degree",
      desc: "I completed my Ordinary Level in 2019, at Kutama College.",
    },
    {
      id: 2,
      category: "education",
      icon: "icon-graduation",
      year: "2021",
      title: "Academic Degree",
      desc: "I completed my Advanced Level in 2021, at Visitation Makumbi.",
    },
    {
      id: 3,
      category: "education",
      icon: "icon-graduation",
      year: "2022-2026",
      title: "  Bachelor of Technology Degree in Software Engineering",
      desc: "I am currently studying  Bachelor of Technology Degree in Software Engineering at Harare Institute of Technology.",
    },
    {
      id: 4,
      category: "experience",
      icon: "icon-briefcase",
      year: "2023",
      title: "Web Designing Project",
      desc: "I completed an online marketplace project using HTML, CSS, and JavaScript, Node js, Express js and Mongo DB.",
    },
    {
      id: 5,
      category: "experience",
      icon: "icon-briefcase",
      year: "2024",
      title: "Front-End Developer",
      desc: "I am curently working on a website for a local company using React js.",
    },
    
   
  ];

export default Data;
